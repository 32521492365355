import React from 'react'
import Home from './Page1/Home'
import Page2 from './Page2/Page2'
import Page7 from './Page7/Page7'
import Page4 from './Page4/Page4'
import Page3 from './Page3/Page3'
import Page3a from './Page3a/Page3a'
import Page6 from './Page6/Page6'

const LadingPage = () => {
  return (
    <>
        <Home />
                                <Page7 />
                                <Page4 />
                                <Page2 />
                                <Page3 />
                                <Page3a />
                                <Page6 />
    </>
  )
}

export default LadingPage