import React from 'react'
import Home from './Page1/Home'
import Page2 from './Page2/Page2'
import Page4 from './Page4/Page4'
import Page7 from './Page7/Page7'

const HomeDefault = () => {
  return (
    <>
        <Home />
        <Page2 />
        <Page4 />
        <Page7 />
    </>
  )
}

export default HomeDefault