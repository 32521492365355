// eslint-disable-next-line import/no-anonymous-default-export
export default {
    API_URL: "https://api.merklabs.io/graphql",
    STORAGE_URL: "https://api.merklabs.io/documents",
    BUSD: "0x63979CF6bE033bcAA6089D8A4a26C4a04C891eC4",
    FACTORY: "0xC02740a6Fcba52bE03ca62E26A79ED3e042a70B4",
    CHAIN_ID: 97,
    DONTATION_TOKEN_SYMBOL:"USDT",
   
    RPC:"https://data-seed-prebsc-1-s3.binance.org:8545"

}


